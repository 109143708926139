import { SelectChangeEvent } from '@mui/material'
import { useTheme, Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import FormHelperText from '@mui/material/FormHelperText'
import { Error } from '../Models/ValidateError'

// カスタムスタイル
const useStyles = makeStyles(() =>
  createStyles({
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  }),
)

const itemHeight = 48
const itemPaddingTop = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
}

const getStyles = (
  category: string,
  categoryValues: string[],
  theme: Theme,
) => {
  return {
    fontWeight:
      categoryValues.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

// カテゴリ用のコンポーネント
const Category = ({
  categories,
  categoryValues,
  categoryDisabled,
  error,
  onChange,
}: {
  categories: {
    key: string
    value: string
  }[]
  categoryValues: string[]
  categoryDisabled: boolean
  error: Error
  onChange: (e: SelectChangeEvent<string[]>) => void
}) => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <>
      <FormControl
        variant="standard"
        error={error.hasError}
        sx={{
          mt: 2,
          minWidth: 120,
          maxWidth: 300,
        }}
      >
        <InputLabel id={'category-form'}>カテゴリ</InputLabel>
        <Select
          variant="standard"
          labelId={'category-form'}
          id="category-mutiple-chip"
          multiple
          value={categoryValues}
          onChange={onChange}
          input={<Input id="category-mutiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) =>
                categories.map(
                  (category) =>
                    category.key === value && (
                      <Chip
                        key={category.value}
                        label={category.value}
                        className={classes.chip}
                      />
                    ),
                ),
              )}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {categories.map((category) => (
            <MenuItem
              key={category.key}
              value={category.key}
              style={getStyles(category.key, categoryValues, theme)}
              disabled={
                categoryDisabled && !categoryValues.includes(category.key)
              }
            >
              {category.value}
            </MenuItem>
          ))}
        </Select>
        {error.message !== '' && (
          <FormHelperText>{error.message}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default Category
