import './App.css'
import { useEffect, useState, ChangeEvent } from 'react'
import axios from 'axios'
import { StoreInfoType } from './Models/StoreInfoType'
import Category from './Components/Category'
import { ProductType } from './Models/Product'
import { config } from './config'
import SalesArea from 'Components/SalesArea'
import Price from 'Components/Price'
import CombinationResult from 'Components/CombinationResult'
import { Box, Button, SelectChangeEvent, Container } from '@mui/material'
import Header from './Components/Header'
import Footer from 'Components/Footer'
import Description from 'Components/Description'
import ShoppingBag from 'Components/ShoppingBag'
import Note from 'Components/Note'
import { useError, errorDefaults } from 'Hooks/error'
import { validation } from 'Libraries/validate'
import { useForm, ShoppingBagValueType } from 'Hooks/input'

// デフォルトのストア名
export const defaultStore = 'seven_store'

function App() {
  // 初回に入手したストア情報
  const [index, setIndex] = useState<StoreInfoType>({
    store: [],
    sales_area: [],
  })
  // フォームのstate
  const [formState, setFormState] = useForm()
  // 商品組合せ
  const [combination, setCombination] = useState<ProductType[][] | null>(null)
  // バリデーションエラー
  const [errors, setErrors] = useError()

  // 初期ロード時のみの動作
  useEffect(() => {
    axios(`${config.apiHost}/home`)
      .then((result) => {
        setIndex(result.data)
      })
      .catch((reason) => {
        console.log(reason)
      })
      .finally(() => {})
  }, [])

  // 入力カテゴリが変更したときのハンドラ処理
  const handleCategoryChange = (e: SelectChangeEvent<string[]>) => {
    const target = e.target.value as string[]
    setFormState({ categoryValues: target })
    if (target.length === 1 && target[0] === 'all') {
      setFormState({ categoryDisabled: true })
    } else if (1 <= target.length && target.includes('all')) {
      setFormState({ categoryValues: ['all'], categoryDisabled: true })
    } else {
      setFormState({ categoryDisabled: false })
    }
  }

  // 入力販売地域が変更したときのハンドラ処理
  const handleSalesAreaChange = (e: SelectChangeEvent<string>) => {
    setFormState({ salesAreaValue: e.target.value as string })
  }

  // 入力金額が変更したときのハンドラ処理
  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ priceValue: e.target.value })
  }

  // 入力レジ袋が変更したときのハンドラ処理
  const handleShoppingBagChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({ shoppingBagValue: e.target.value as ShoppingBagValueType })
  }

  // 入力を元にAPIを叩いて商品の組合せデータを取得しに行く
  const fetchCombination = () => {
    if (!validation(formState, errors, setErrors)) {
      return
    }
    axios
      .get(`${config.apiHost}/products`, {
        params: {
          store: defaultStore,
          category: formState.categoryValues.join(),
          sales_area: formState.salesAreaValue,
          price: formState.priceValue,
          shopping_bag: formState.shoppingBagValue,
        },
      })
      .then((result) => {
        setCombination(result.data.data)
        setErrors(errorDefaults)
      })
      .catch((reason) => {
        console.log(reason)
      })
  }

  return (
    <>
      <Header />
      <main>
        <Container sx={{ mt: 5 }}>
          <Box
            component="div"
            sx={{
              width: { xs: '95%', sm: '85%', md: '75%' },
              mx: 'auto',
            }}
          >
            <Description />
          </Box>
          <Box
            component="div"
            sx={{
              width: { xs: '90%', sm: '75%', md: '50%' },
              mx: 'auto',
            }}
          >
            {index?.store.map((store, index) => (
              <>
                <Category
                  key={'category' + index}
                  categories={store.categories}
                  categoryValues={formState.categoryValues}
                  categoryDisabled={formState.categoryDisabled}
                  error={errors.category}
                  onChange={handleCategoryChange}
                />
              </>
            ))}
            <br />

            <SalesArea
              salesAreaValue={formState.salesAreaValue}
              error={errors.salesArea}
              onChange={handleSalesAreaChange}
            />
            <br />

            <Price
              priceValue={formState.priceValue}
              error={errors.price}
              onChange={handlePriceChange}
            />
            <br />

            <Box
              component="div"
              sx={{
                mt: 3.5,
              }}
            >
              <ShoppingBag
                error={errors.shoppingBag}
                onChange={handleShoppingBagChange}
              />
            </Box>

            {formState.shoppingBagValue !== 'none' && <Note />}

            <Box component="div" sx={{ mt: 8, textAlign: 'center' }}>
              <Button
                variant="contained"
                color="inherit"
                size="large"
                onClick={() => {
                  fetchCombination()
                }}
              >
                組合せを取得
              </Button>
            </Box>
          </Box>

          <CombinationResult combination={combination} />
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default App
