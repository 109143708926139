import { errorDefaults, errorDefaultState } from 'Hooks/error'
import { FormState } from 'Hooks/input'
import { ValidateError } from '../Models/ValidateError'

export const validation = (
  formState: FormState,
  errors: ValidateError,
  setErrors: React.Dispatch<ValidateError>,
): boolean => {
  const checkHasError = errorDefaults

  // カテゴリのバリデーション
  if (formState.categoryValues.length === 0) {
    const categoryErrorState = {
      hasError: true,
      message: '選択してください',
    }
    checkHasError.category = categoryErrorState
    setErrors({ category: categoryErrorState })
  } else if (errors.category.hasError) {
    checkHasError.category = errorDefaultState
    setErrors({ category: errorDefaultState })
  }

  // 販売地域のバリデーション
  if (formState.salesAreaValue === '') {
    const salesAreaErrorState = {
      hasError: true,
      message: '選択してください',
    }
    checkHasError.salesArea = salesAreaErrorState
    setErrors({ salesArea: salesAreaErrorState })
  } else if (errors.salesArea.hasError) {
    checkHasError.salesArea = errorDefaultState
    setErrors({ salesArea: errorDefaultState })
  }

  // 金額のバリデーション
  if (formState.priceValue === '') {
    const priceErrorState = {
      hasError: true,
      message: '入力してください',
    }
    checkHasError.price = priceErrorState
    setErrors({ price: priceErrorState })
  } else if (formState.priceValue.match(/^\d{1,3}$/g) === null) {
    const priceErrorState = {
      hasError: true,
      message: '3桁以内の半角数字で入力してください',
    }
    checkHasError.price = priceErrorState
    setErrors({ price: priceErrorState })
  } else if (errors.price.hasError) {
    checkHasError.price = errorDefaultState
    setErrors({ price: errorDefaultState })
  }

  // レジ袋有無のバリデーション
  if (formState.shoppingBagValue === '') {
    const shoppingBagErrorState = {
      hasError: true,
      message: '選択してください',
    }
    checkHasError.shoppingBag = shoppingBagErrorState
    setErrors({ shoppingBag: shoppingBagErrorState })
  } else if (errors.shoppingBag.hasError) {
    checkHasError.shoppingBag = errorDefaultState
    setErrors({ shoppingBag: errorDefaultState })
  }

  for (const key of Object.keys(checkHasError)) {
    if (checkHasError[key].hasError) {
      return false
    }
  }
  return true
}
