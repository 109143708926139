import { ChangeEvent } from 'react'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import { Error } from '../Models/ValidateError'

// ぴったり金額入力フォームを表すコンポーネント
const Price = ({
  priceValue,
  error,
  onChange,
}: {
  priceValue: string
  error: Error
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <>
      <FormControl
        variant="standard"
        error={error.hasError}
        sx={{
          mt: 2.5,
          width: '15ch',
        }}
      >
        <InputLabel htmlFor="price-form">金額(税込み)</InputLabel>
        <Input
          id="price-form"
          value={priceValue}
          onChange={onChange}
          inputProps={{
            inputMode: "numeric",
          }}
          endAdornment={<InputAdornment position="end">円</InputAdornment>}
        />
        {error.message === '' ? (
          <FormHelperText>※3桁以内の半角数字で入力してください</FormHelperText>
        ) : (
          <FormHelperText>{error.message}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default Price
