import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import { Typography } from '@mui/material'

const Description = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        borderRadius: 3,
        backgroundColor: '#F7F6F6',
        my: 4,
        p: 3,
      }}
    >
      <Typography sx={{ mb: { xs: 2.5, md: 1 } }}>
        <Box component="div" sx={{ display: 'flex' }}>
          <Box component="div">
            <CheckIcon fontSize="small" sx={{ mr: 1 }} />
          </Box>
          <Box component="div">
            手持ちの小銭
            <Box
              component="span"
              style={{ fontWeight: 'bold', textDecoration: 'underline'}}
            >
              ピッタリ
            </Box>
            の金額で購入することができるセブンイレブンの商品組み合わせを教えてくれます。
          </Box>
        </Box>
      </Typography>
      <Typography sx={{ mb: { xs: 2.5, md: 1 } }}>
        <Box component="div" sx={{ display: 'flex' }}>
          <Box component="div">
            <CheckIcon fontSize="small" sx={{ mr: 1 }} />
          </Box>
          <Box component="div">
            食べたいものが組み合わせに含まれていないかもしれません。
          </Box>
        </Box>
      </Typography>
      <Typography sx={{ mb: { xs: 2.5, md: 1 } }}>
        <Box component="div" sx={{ display: 'flex' }}>
          <Box component="div">
            <CheckIcon fontSize="small" sx={{ mr: 1 }} />
          </Box>
          <Box component="div">
            しかしながら、小銭ピッタリで払いきったときのあの快感を手軽に味わうことができます。
          </Box>
        </Box>
      </Typography>
      <Typography>
        <Box component="div" sx={{ display: 'flex' }}>
          <Box component="div">
            <CheckIcon fontSize="small" sx={{ mr: 1 }} />
          </Box>
          <Box component="div">どうぞお試しください。</Box>
        </Box>
      </Typography>
    </Box>
  )
}

export default Description
