import Box from '@mui/material/Box'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { Typography } from '@mui/material'

const Note = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        borderRadius: 3,
        backgroundColor: '#F7F6F6',
        mt: 2,
        mb: 8,
        p: 2,
      }}
    >
      <Typography>
        <Box component="div" sx={{ display: 'flex' }}>
          <Box component="div">
            <WarningAmberIcon fontSize="small" sx={{ mr: 1 }} />
          </Box>
          <Box component="div" sx={{ fontSize: '0.8rem' }}>
            レジ袋の大きさについては、店員さんが品数をみて決めます。
            <Box component="span" sx={{ display: 'inline-block' }}>
              たいていの場合、レジ袋の大きさは "あり"
              でことたりると思われますが、金額が合わないケースもあるため、ご心配の方は店員さんへ大きさや値段をご確認ください。
            </Box>
          </Box>
        </Box>
      </Typography>
    </Box>
  )
}

export default Note
