import { Box, Typography, BottomNavigation } from '@mui/material'

const copyright = (): string => {
  const year = new Date().getFullYear()
  if (year === 2023) {
    return `© ${year} セブンイレブン ガチャ`
  }
  return `© 2023-${year} セブンイレブン ガチャ`
}

const Footer = () => {
  return (
    <Box sx={{ width: '100%', position: 'absolute', bottom: 0 }}>
      <BottomNavigation sx={{ height: '100px', bgcolor: '#0E6942' }}>
        <Typography
          variant="caption"
          sx={{ color: '#e0f2f1', lineHeight: '100px' }}
        >
          {copyright()}
        </Typography>
      </BottomNavigation>
    </Box>
  )
}

export default Footer
