import { useReducer } from 'react'

// レジ袋の値の型
export type ShoppingBagValueType = 'none' | 'normal' | 'big' | ''
export const defaultShoppingBag: ShoppingBagValueType = 'none'

// デフォルトのレジ袋選択肢
export type FormState = {
  categoryValues: string[] // カテゴリの値['onigiri', 'bento', ...]
  categoryDisabled: boolean // カテゴリを3つ以上選択した場合にセレクトボックスをdisabledにするか
  salesAreaValue: string // 販売地域
  priceValue: string // 金額
  shoppingBagValue: ShoppingBagValueType // レジ袋
}

// dispatch関数に渡す、更新するstateの型
type UpdateState = {
  categoryValues?: string[]
  categoryDisabled?: boolean
  salesAreaValue?: string
  priceValue?: string
  shoppingBagValue?: ShoppingBagValueType
}

const initialState: FormState = {
  categoryValues: [],
  categoryDisabled: false,
  salesAreaValue: '',
  priceValue: '',
  shoppingBagValue: 'none',
}

export const useForm = () => {
  return useReducer(
    (state: FormState, updateStateObj: UpdateState) => ({
      ...state,
      ...updateStateObj,
    }),
    initialState,
  )
}
