import { defaultShoppingBag } from '../Hooks/input'
import { ChangeEvent } from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormHelperText from '@mui/material/FormHelperText'
import { Error } from '../Models/ValidateError'

// レジ袋の有無を選択するコンポーネント
const ShoppingBag = ({
  error,
  onChange,
}: {
  error: Error
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  return (
    <>
      <FormControl
        variant="standard"
        component="fieldset"
        error={error.hasError}
      >
        <FormLabel component="legend">レジ袋</FormLabel>
        <RadioGroup defaultValue={defaultShoppingBag}>
          <FormControlLabel
            value={defaultShoppingBag}
            control={
              <Radio
                name="shopping_bag"
                color="default"
                value="none"
                id={'shopping_bag_radio_button_none'}
                onChange={onChange}
              />
            }
            label="なし"
            labelPlacement="end"
          />
          <FormControlLabel
            value="normal"
            control={
              <Radio
                name="shopping_bag"
                color="default"
                value="normal"
                id={'shopping_bag_radio_button_normal'}
                onChange={onChange}
              />
            }
            label="あり"
            labelPlacement="end"
          />
          <FormControlLabel
            value="big"
            control={
              <Radio
                name="shopping_bag"
                color="default"
                value="big"
                id={'shopping_bag_radio_button_big'}
                onChange={onChange}
              />
            }
            label="あり（特大）"
            labelPlacement="end"
          />
        </RadioGroup>
        {error.message === '' ? (
          <FormHelperText>
            ※「あり」は+3円、「あり（特大）」は+5円
          </FormHelperText>
        ) : (
          <FormHelperText>{error.message}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default ShoppingBag
