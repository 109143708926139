import { SelectChangeEvent } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import FormHelperText from '@mui/material/FormHelperText'
import { Error } from '../Models/ValidateError'

// JIS X 0401にて都道府県の並び順が定められいるようなのでそれに準拠
const salesArea: { key: string; value: string }[] = [
  { key: 'hokkaido', value: '北海道' },
  { key: 'aomoriken', value: '青森県' },
  { key: 'iwateken', value: '岩手県' },
  { key: 'miyagiken', value: '宮城県' },
  { key: 'akitaken', value: '秋田県' },
  { key: 'yamagataken', value: '山形県' },
  { key: 'hukushimaken', value: '福島県' },
  { key: 'ibarakiken', value: '茨城県' },
  { key: 'tochigiken', value: '栃木県' },
  { key: 'gunmaken', value: '群馬県' },
  { key: 'saitamaken', value: '埼玉県' },
  { key: 'chibaken', value: '千葉県' },
  { key: 'tokyoto', value: '東京都' },
  { key: 'kanagawaken', value: '神奈川県' },
  { key: 'nigataken', value: '新潟県' },
  { key: 'toyamaken', value: '富山県' },
  { key: 'ishikawaken', value: '石川県' },
  { key: 'hukuiken', value: '福井県' },
  { key: 'yamanashiken', value: '山梨県' },
  { key: 'naganoken', value: '長野県' },
  { key: 'gihuken', value: '岐阜県' },
  { key: 'sizuokaken', value: '静岡県' },
  { key: 'aichiken', value: '愛知県' },
  { key: 'mieken', value: '三重県' },
  { key: 'shigaken', value: '滋賀県' },
  { key: 'kyotohu', value: '京都府' },
  { key: 'osakahu', value: '大阪府' },
  { key: 'hyogoken', value: '兵庫県' },
  { key: 'naraken', value: '奈良県' },
  { key: 'wakayamaken', value: '和歌山県' },
  { key: 'tottoriken', value: '鳥取県' },
  { key: 'shimaneken', value: '島根県' },
  { key: 'okayamaken', value: '岡山県' },
  { key: 'hiroshimaken', value: '広島県' },
  { key: 'yamaguchiken', value: '山口県' },
  { key: 'tokushimaken', value: '徳島県' },
  { key: 'kagawaken', value: '香川県' },
  { key: 'ehimeken', value: '愛媛県' },
  { key: 'kochiken', value: '高知県' },
  { key: 'hukuokaken', value: '福岡県' },
  { key: 'sagaken', value: '佐賀県' },
  { key: 'nagasakiken', value: '長崎県' },
  { key: 'kumamotoken', value: '熊本県' },
  { key: 'oitaken', value: '大分県' },
  { key: 'miyazakiken', value: '宮崎県' },
  { key: 'kagoshimaken', value: '鹿児島県' },
  { key: 'okinawaken', value: '沖縄県' },
]

// 販売地域を表すコンポーネント
const SalesArea = ({
  salesAreaValue,
  error,
  onChange,
}: {
  salesAreaValue: string
  error: Error
  onChange: (e: SelectChangeEvent<string>) => void
}) => {
  return (
    <>
      <FormControl
        variant="standard"
        error={error.hasError}
        sx={{
          mt: 2.5,
          minWidth: 120,
          maxWidth: 300,
        }}
      >
        <InputLabel id="demo-simple-select-label">販売地域</InputLabel>
        <Select
          variant="standard"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={salesAreaValue}
          onChange={onChange}
        >
          {salesArea.map((area) => (
            <MenuItem key={area.key} value={area.key}>
              {area.value}
            </MenuItem>
          ))}
        </Select>
        {error.message !== '' && (
          <FormHelperText>{error.message}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default SalesArea
