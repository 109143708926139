import { useReducer } from 'react'
import { ValidateError } from '../Models/ValidateError'

// バリデーションエラーメッセージ
export const errorDefaultState = { hasError: false, message: '' }
export const errorDefaults: ValidateError = {
  category: errorDefaultState,
  salesArea: errorDefaultState,
  price: errorDefaultState,
  shoppingBag: errorDefaultState,
}

export const useError = () => {
  return useReducer(
    (errors: ValidateError, updateStateObj: ValidateError): ValidateError => ({
      ...errors,
      ...updateStateObj,
    }),
    errorDefaults,
  )
}
