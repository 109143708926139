import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ color: '#e0f2f1', backgroundColor: '#0E6942' }}
      >
        <Toolbar>
          <Typography
            align="center"
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, fontWeight: 'bold' }}
          >
            セブンイレブン ガチャ
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Header
