import { ProductType } from '../Models/Product'
import { Box, Typography, Link, List, ListItem } from '@mui/material'

// 商品組合せ結果出力用のコンポーネント
const CombinationResult = ({
  combination,
}: {
  combination: ProductType[][] | null
}) => {
  if (combination === null) {
    return (
      <>
        <Box
          component="div"
          sx={{
            textAlign: { xs: 'left', sm: 'center' },
            width: { xs: '90%', sm: '75%', md: '50%' },
            mx: 'auto',
            my: 8
          }}
        >
          ここに商品の組み合わせ結果が表示されます
        </Box>
      </>
    )
  }
  if (combination.length === 0) {
    return (
      <>
        <Box
          component="div"
          sx={{
            textAlign: { xs: 'left', sm: 'center' },
            width: { xs: '90%', sm: '75%', md: '50%' },
            mx: 'auto',
            my: 8
          }}
        >
          ぴったりの金額になる組み合わせは存在しませんでした
        </Box>
      </>
    )
  }
  return (
    <>
      <Box
        component="div"
        sx={{
          textAlign: { xs: 'left', sm: 'center' },
          width: { xs: '90%', sm: '75%', md: '50%' },
          mx: 'auto',
          mt: 8,
          mb: 5,
        }}
      >
        組み合わせ結果を取得しました。
        <Box component="span" sx={{ display: 'inline-block' }}>
          すべて
          <Box component="span" style={{ textDecoration: 'underline' }}>
            税抜き価格
          </Box>
          で表示しています。
        </Box>
      </Box>
      {combination.map((set, index) => (
        <>
          <Box
            key={index}
            component="div"
            sx={{
              borderRadius: 5,
              backgroundColor: '#F7F6F6',
              width: { xs: '90%', sm: '75%', md: '60%' },
              mx: 'auto',
              my: 5,
              px: { xs: 2, sm: 3 },
              py: 3,
            }}
          >
            <Typography sx={{ mb: 1 }} component="div">
              組合せ{index + 1}
            </Typography>
            {set.map((product, productIndex) => (
              <>
                <List dense={true}>
                  <ListItem key={productIndex}>
                    <Link
                      href={product.product_detail_link}
                      underline="none"
                      target="_blank"
                      rel="noopener noreferrer"
                      key={productIndex}
                    >
                      {product.name}：{product.no_tax_price}円
                    </Link>
                  </ListItem>
                </List>
              </>
            ))}
          </Box>
        </>
      ))}
    </>
  )
}

export default CombinationResult
